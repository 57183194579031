import React, { forwardRef, HTMLAttributes } from 'react'
import { cva } from 'class-variance-authority'
import { twMerge } from 'tailwind-merge'

const variants = cva('flex', {
  variants: {
    variant: {
      bordered: ['border', 'border-black', 'border-2'],
    },
    centerAll: {
      true: ['items-center', 'justify-center'],
    },
    full: {
      true: ['w-full', 'h-full'],
    },
  },
  defaultVariants: {
    variant: undefined,
  },
})

interface BoxProps extends HTMLAttributes<HTMLDivElement> {
  variant?: 'bordered'
  centerAll?: boolean
  full?: boolean
}

const Box = forwardRef<HTMLDivElement, BoxProps>((props, ref) => {
  const { variant, centerAll, children, className, ...rest } = props

  return (
    <div
      className={twMerge(
        variants({
          variant,
          centerAll,
          className,
        }),
      )}
      {...rest}
      ref={ref}
    >
      {children}
    </div>
  )
})
Box.displayName = 'Box'
export { Box }
